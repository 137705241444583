/* src/components/AvatarCreator.css */

.avatar-creator {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.avatar-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.avatar-preview {
  border: 1px solid #ddd;
  margin-right: 20px;
  width: 50%;
}

.avatar-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  width: 50%;
}

.control-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.control-section h3 {
  margin-right: 10px;
}

.header {
  text-align: left;
  width: 100%;
  font-size: 2em;
  margin-bottom: 10px;
}

.subheader {
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
}

.avatar-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.avatar-options button {
  background: none;
  border: 2px solid grey;
  margin: 5px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.avatar-options button.selected {
  border: 2px solid blue;
}

.avatar-options img {
  width: 100%;
  height: 100%;
}

.save-avatar-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  align-self: center;
}

.save-avatar-button:hover {
  background-color: #0056b3;
}

/* Mobile view */
@media (max-width: 1024px) {
  .avatar-content {
    flex-direction: column;
  }

  .avatar-preview,
  .avatar-controls {
    width: 100%;
  }

  .avatar-preview {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .control-section {
    flex-direction: column;
    align-items: flex-start;
  }
}
