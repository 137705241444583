@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/tailwind.css or src/index.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background: url("../public/background.jpg") no-repeat center center fixed;
  background-size: cover;
}

.container {
  padding-top: 20px; /* To prevent the content from going off the top of the page */
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-popup {
  animation: popup 0.3s ease-out;
}

.popup {
  white-space: nowrap; /* Ensure text stays on one line */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

nav .container {
  padding-top: 0 !important;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
